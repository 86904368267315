<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

import {
  fetchUserStructuresTypesApi,
  fetchDirectionsListApi,
  fetchDivisionsListApi,
  fetchDepartmentsListApi,
  fetchServicesListApi,
  fetchTakeAwayMealRequestDestinationsApi,
  fetchTakeAwayMealRequestExternalDestinationsApi,
  fetchMealsTypesApi
} from "@/api/common";
export default {
  page: {
    title: "Nouvelle requête de demande Repas emporté",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  import : {
    fetchUserStructuresTypesApi,
    fetchDirectionsListApi,
    fetchDivisionsListApi,
    fetchDepartmentsListApi,
    fetchServicesListApi,
    fetchTakeAwayMealRequestDestinationsApi,
    fetchTakeAwayMealRequestExternalDestinationsApi,
    fetchMealsTypesApi
  },
  data() {
    return {
      title: "Nouvelle requête de demande Repas emporté",
      
      destinationTypes: [],
      externalDestinations: [],
      structureTypes: [],
      divisions: [],
      directions: [],
      departments: [],
      services: [],
      structures: [],
      identityDocTypes: [],
      supportTypes: [],
      mealsTypes: [],
      takeawayMealRequestForm: {
        uuid: "",
        id: "",
        date: new Date().toISOString().substr(0, 10),
        location_type: "",
        structure_type: "",
        structure: "",
        externalDestination:"",
        externalDestinationTxt:"",
        reason: "",
        analyticAccount: "",
        file: "",
        notes: "",
        meals: [
          {
            meal: "",
            qty: "",
            description: "",
          },
        ],
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Requêtes",
          href: "/",
        },
        {
          text: "Repas emporté",
          active: '/',
        },
        {
          text: "Nouveau",
          active: '/',
        },
      ],
    };
  },

  validations: {
    takeawayMealRequestForm: {
      // date: { required },
    },
  },

  created() {
    this.fetchMealsTypes();
    this.fetchExternalDestinations();
    this.fetchUserStructuresTypes();
    this.fetchDirectionsList();
    this.fetchDivisionsList();
    this.fetchDepartmentsList();
    this.fetchServicesList();
    this.fetchTakeAwayMealRequestDestinations();
    this.fetchSingleTakeawayRequestData();
  },

  methods: {
    getDestinationObject(search){
        this.destinationTypes.forEach((singleDest, index) => {
          console.log(index);
        if(singleDest.id == search){
          console.log("Found it" +singleDest);
          return singleDest;
        }
      });
    },
    fetchSingleTakeawayRequestData() {
      var _this = this;
      this.$http
        .post("/ops/requests/takeawaymeals/getSingleRequestData/" + this.$route.params.uid)

        .then((res) => {
          var status = res.data.original.status;
          var tamr = res.data.original.data.tamr;
          switch (status) {
            case 200:
              _this.takeawayMealRequestForm.id = tamr.id;
              _this.takeawayMealRequestForm.uuid = tamr.uuid;
              _this.takeawayMealRequestForm.date = tamr.date;
              _this.takeawayMealRequestForm.notes = tamr.comments;
              _this.takeawayMealRequestForm.location_type = tamr.locationObj;
              _this.takeawayMealRequestForm.externalDestination = tamr.locationTypeObj;
              _this.takeawayMealRequestForm.externalDestinationTxt = tamr.location_txt;
              _this.takeawayMealRequestForm.reason = tamr.reason;
              _this.takeawayMealRequestForm.analyticAccount = tamr.analytic_account;
              _this.takeawayMealRequestForm.meals = tamr.entries;
              break;

            case 500:
              _this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          _this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    onchangeStructureType(){
      var struct = this.takeawayMealRequestForm.structure_type.id;
      this.structures = [];
      switch (struct) {
        case 'direction':
          this.structures = this.directions;
        break;

        case 'division':
          this.structures = this.divisions;
        break;

        case 'department':
          this.structures = this.departments;
        break;

        case 'service':
          this.structures = this.services;
        break;
      
        default:
          
          break;
      }
    },

    _validateMealRowData(){
      var valid = true;
      var mealsData = this.takeawayMealRequestForm.meals;
      mealsData.forEach((singleMealEntry, index) => {
        index++;
          if(singleMealEntry.meal == ""){
            this.$toast.warning("Veuillez choisir un repas dans la line N°: " + index);
            valid = false;
          }

          if(singleMealEntry.qty == ""){
            this.$toast.warning("Veuillez choisir la quantité dans la line N°: " + index);
            valid = false;
          }
      });

      return valid;
    },
    AddformData() {
      if(this._validateMealRowData()){
        this.takeawayMealRequestForm.meals.push({
          meal: "",
          qty: "",
          description: "",
        });
      }
    },
    /**
     * Delete the row
     */
    fetchMealsTypes(){
      fetchMealsTypesApi()
      .then((res) => (this.mealsTypes = res.data.original.data))
              .catch(() => {})
              .finally(() => {});
    },
    fetchExternalDestinations(){
      fetchTakeAwayMealRequestExternalDestinationsApi()
      .then((res) => (this.externalDestinations = res.data.original.data))
              .catch(() => {})
              .finally(() => {});
    },
    fetchTakeAwayMealRequestDestinations(){
      fetchTakeAwayMealRequestDestinationsApi()
              .then((res) => (this.destinationTypes = res.data.original.data))
              .catch(() => {})
              .finally(() => {});
    },
    fetchDirectionsList(){
      fetchDirectionsListApi()
        .then((res) => (this.directions = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchDivisionsList(){
      fetchDivisionsListApi()
        .then((res) => (this.divisions = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchDepartmentsList(){
      fetchDepartmentsListApi()
        .then((res) => (this.departments = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchServicesList(){
      fetchServicesListApi()
        .then((res) => (this.services = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchUserStructuresTypes() {
      fetchUserStructuresTypesApi()
        .then((res) => (this.structureTypes = res.data.original.data))
        .catch(() => {})
        .finally(() => {});
    },
    deleteRow(index) {
      if (confirm("Êtes-vous sûr de vouloir supprimer ce visiteur?"))
        this.takeawayMealRequestForm.meals.splice(index, 1);
    },


    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      var router = this.$router;
      this.$http
        .post(
          "/ops/requests/takeawaymeals/update",
          this.takeawayMealRequestForm
        )
        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              router.push({ name: "requests.takeawaymeals.index" });
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit" enctype="multipart/form-data">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="formrow-inputCity">Date *</label>
                    <input type="date" v-model="takeawayMealRequestForm.date" class="form-control" name="" id="">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="formrow-inputCity">Destination *</label>
                    <multiselect
                      v-model="takeawayMealRequestForm.location_type"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="destinationTypes"
                      placeholder="Select user structure"
                      :allow-empty="false"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                    
                  </div>
                </div>

                <div class="col-lg-4" v-if="takeawayMealRequestForm.location_type.id == 'external'">
                  <div class="form-group">
                    <label for="formrow-inputCity">Destination externe *</label>
                    <multiselect
                      v-model="takeawayMealRequestForm.externalDestination"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="externalDestinations"
                      placeholder="Select user structure"
                      :allow-empty="false"
                    >
                      <template slot="singleDestination" slot-scope="{ destination }">{{
                        destination.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-12" v-if="takeawayMealRequestForm.location_type.id == 'external'">
                  <div class="form-grou">
                    <label for="">Détails de Destination</label>
                    <input type="text" class="form-control" v-model="takeawayMealRequestForm.externalDestinationTxt">
                  </div>
                </div>
                <div class="col-lg-4" v-if="takeawayMealRequestForm.location_type.id == 'internal'">
                  <div class="form-group">
                    <label for="formrow-inputCity">Type de structure *</label>
                    <multiselect
                      v-model="takeawayMealRequestForm.structure_type"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="structureTypes"
                      placeholder="Select user structure"
                      :allow-empty="false"
                      @input="onchangeStructureType"
                    >
                      <template slot="singleStruct" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>

                

                <div class="col-md-4" v-if="takeawayMealRequestForm.location_type.id == 'internal'">
                  <div class="form-group">
                    <label for="formrow-password-input"
                      >Structure *</label
                    >
                    <multiselect
                      v-model="takeawayMealRequestForm.structure"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="structures"
                      placeholder="Select user structure"
                      :allow-empty="false"
                    >
                      <template slot="singleStruct" slot-scope="{ struct }">{{
                        struct.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="formrow-password-input"
                      >Motif de la demande *</label
                    >
                    <input
                      v-model="takeawayMealRequestForm.reason"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="">Imputation *</label>
                    <input type="text" class="form-control" v-model="takeawayMealRequestForm.analyticAccount">
                  </div>
                </div>
              </div>

             <hr>  
              <div>
                <div
                  v-for="(requestMeals, index) in takeawayMealRequestForm.meals"
                  :key="requestMeals.id"
                  class="row"
                >
                  <div class="form-group col-lg-4">
                    <label for="resume">Repas</label>
                    <multiselect
                      v-model="requestMeals.meal"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="mealsTypes"
                      placeholder="Selectionner un repas"
                      :allow-empty="false"
                    >
                      <template slot="singleMealType" slot-scope="{ mealType }">{{
                        mealType.name
                      }}</template>
                    </multiselect>
                  </div>

                  <div class="form-group col-lg-3">
                    <label for="subject">Qty</label>
                    <input
                      id="subject"
                      v-model="requestMeals.qty"
                      type="number" min="1"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group col-lg-4">
                    <label for="message">Observations</label>
                    <input
                      id="subject"
                      v-model="requestMeals.description"
                      type="text"
                      class="form-control"
                    />
                  </div>

                  <div class="col-lg-1 align-self-center">
                    <a
                      v-if="
                        takeawayMealRequestForm.meals.length > 1 &&
                        index != 0
                      "
                      href="javascript:void(0)"
                      ><i class="bx bx-trash-alt" @click="deleteRow(index)"></i
                    ></a>
                  </div>
                </div>
                <input
                  type="button"
                  class="btn btn-success mt-3 mt-lg-0"
                  value="Ajouter"
                  @click="AddformData"
                />
              </div>
              <br />
              <!-- <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="formrow-password-input">Attachement</label>
                    <input type="file" class="form-control" />
                  </div>
                </div>
              </div> -->

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="formrow-inputCity">Commentaires</label>
                    <textarea
                      class="form-control"
                      v-model="takeawayMealRequestForm.notes"
                      cols="30"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div>
                <button :disabled="$v.takeawayMealRequestForm.$invalid" class="btn btn-primary" type="submit">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>